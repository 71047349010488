import fire from "./config/firebase";
import React, { useEffect, useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
import "./App.css";
import santa from "./assets/santa.png";

function App() {
  const [members, setMembers] = useState([]);
  const memberRef = useRef(members);
  const [selectedMemberName, setSelectedMemberName] = useState("");
  const [userEmailAddress, setUserEmailAddress] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const db = fire.firestore();
  const auth = fire.auth();

  // UseEffect to listen to the auth state change (only once when the component mounts)
  useEffect(() => {
    authListener();
  }, []);  // Empty dependency array, ensuring it's called only once on mount

  useEffect(() => {
    memberRef.current = members;
  }, [members]);

  // Authentication listener to handle the sign-in with the email link
  const authListener = () => {
    if (auth.isSignInWithEmailLink(window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");

      // If no email found in localStorage, prompt for it
      if (!email) {
        email = window.prompt("Please provide your email for confirmation.");
      }

      if (email) {
        // Sign in with email link
        auth
          .signInWithEmailLink(email, window.location.href)
          .then((result) => {
            window.localStorage.removeItem("emailForSignIn");
            setCurrentUser(result.user);
          })
          .catch((error) => {
            console.error("Error signing in:", error);
            setCurrentUser(null);
          });
      }
    }
  };

  // Handle email input change
  const getUserEmailAddress = (e) => {
    setUserEmailAddress(e.target.value);
  };

  // Submit the email address to send sign-in link
  const submitEmailAddress = () => {
    const actionCodeSettings = {
      url: "https://secret-santa-celebration.web.app/",
      // url:"http://localhost:3000/",
      handleCodeInApp: true,
    };

    auth
      .sendSignInLinkToEmail(userEmailAddress, actionCodeSettings)
      .then(() => {
        window.localStorage.setItem("emailForSignIn", userEmailAddress);
        setUserEmailAddress("");
        alert("Please check your email to sign in!");
      })
      .catch((error) => {
        console.error("Error sending sign-in email:", error);
      });
  };

  // Handle adding members and storing data in Firestore
  const getMembersArray = () => {
    if ($("#members-name").val() !== "") {
      $(".members-dropdown").show();
      const docId = uuidv4();
      const pushOnlyArray = $("#members-name").val().split(", ");
      let membersData = [];
      let memberNames = [];
      let memberEmails = [];
      const totalMembers = pushOnlyArray.length / 2;

      for (let i = 0; i < pushOnlyArray.length; i++) {
        if (i % 2 === 0) {
          memberNames.push(pushOnlyArray[i]);
        } else {
          memberEmails.push(pushOnlyArray[i]);
        }
      }

      for (let j = 0; j < totalMembers; j++) {
        let memberData = {
          name: memberNames[j],
          email: memberEmails[j],
          assigned: false,
          partner: "",
        };
        membersData.push(memberData);
      }

      setMembers(membersData);
      const docRef = db.collection("secret_santa_draws").doc(docId);

      docRef.set({
        user_email: auth.currentUser.email,
        secret_santa_id: docId,
        members: membersData,
      });

      generateRandomName(docRef);
      $("#members-name").val("");
      alert("Mails have been sent!");
    } else {
      alert("Please Add Some members' names");
    }
  };

  // Generate random names for Secret Santa pairs
  const generateRandomName = (docRef) => {
    docRef.get().then((doc) => {
      if (doc.exists) {
        const data = doc.data();
        data["members"].forEach((selectedMember) => {
          if (selectedMember["partner"] === "") {
            let namesToChooseFrom = [];
            memberRef.current.forEach((member) => {
              if (member["name"] !== selectedMember["name"] && !member["assigned"]) {
                namesToChooseFrom.push(member);
              }
            });

            if (namesToChooseFrom.length > 0) {
              const totalNamesToChooseFrom = namesToChooseFrom.length;
              const selectedMemberIndex = getRandomInt(0, totalNamesToChooseFrom);

              const selectedMemberName = namesToChooseFrom[selectedMemberIndex]["name"];
              setSelectedMemberName(selectedMemberName);

              memberRef.current.forEach((member) => {
                if (member["name"] === namesToChooseFrom[selectedMemberIndex]["name"]) {
                  member["assigned"] = true;
                }
                if (member["name"] === selectedMember["name"]) {
                  member["partner"] = namesToChooseFrom[selectedMemberIndex]["name"];
                }
              });

              setMembers(memberRef.current);

              docRef.update({
                members: memberRef.current,
              }).then(() => {
                console.log("Member set to assigned");
              }).catch((error) => {
                console.log("Error updating document: ", error);
              });
            }
          }
        });
      }
    });
  };

  // Helper function to get a random integer for selecting a partner
  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  };

  // Sign out the user
  const signOut = () => {
    auth
      .signOut()
      .then(() => {
        setCurrentUser(null);
        console.log("User signed out");
      })
      .catch((error) => {
        console.log("Error signing out: ", error);
      });
  };

  return (
    <div className="app-wrap">
      <header className="header">
        <h4>Secret Santa</h4>
        {currentUser !== null ? (
          <div className="logout" onClick={signOut}>
            Logout
          </div>
        ) : (
          <div></div>
        )}
      </header>

      <div className="row ml-0 mr-0">
        {currentUser === null ? (
          <div className="col-sm-12 text-center">
            <div className="col-sm-12 text-center mt-5">
              <img className="santa" src={santa} alt="Secret Santa" />
              <h3>Enter your email address</h3>
            </div>
            <div className="col-sm-12 text-center mt-3 user-email-address">
              <input
                className="form-control"
                id="email-address"
                value={userEmailAddress}
                placeholder="name@example.com"
                onChange={getUserEmailAddress}
              />
              <button
                type="button"
                className="btn btn-info mt-3 yes"
                onClick={submitEmailAddress}
              >
                Submit
              </button>
            </div>
          </div>
        ) : (
          <div className="col-sm-12 text-center add-secret-key">
            <div className="form-group add-secret-santa text-center">
              <img className="santa" src={santa} alt="Secret Santa" />
              <h5>
                Add your team members’ name and email:
                <br /> eg: First Name, firstname@example.com
              </h5>
              <textarea
                className="form-control text-area"
                placeholder="First Name, firstname@example.com, Second Name, secondname@example.com"
                rows={5}
                id="members-name"
              ></textarea>
              <button
                type="button"
                className="btn btn-info mt-3 addMembers"
                onClick={getMembersArray}
              >
                Get Secret Santa
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="copyright">
        Designed by CT Solutions &copy; 2024
      </div>
    </div>
  );
}

export default App;
